import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import calletres from '../../assets/logos/calletres.png';
import consorcio from '../../assets/logos/consorcio.png';


const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Cases = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'cards',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Casos de',
    titleColor: 'Éxito',
    paragraph: 'Clientes que han logrado una mejora de su negocio',
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>


            <article className="card3">
            <Image 
                  src={calletres}
                  alt="Calle Tres logo"
                  className="card3__image"  
                  width={120}
                  height={100}
              />
              <div className="card3__content | flow">
                <div className="card3__content--container | flow">
                  <h2 className="card3__title heading">Calle Tres</h2>
                  <p className="card3__description description">
                    Análisis de Datos
                    <br />
                    Se analizo los datos de de encuestas enfocados en un estudio entregando un dashboard interactivo para cruce de información.
                  </p>
                </div>
                <a className="card3__button"   href="https://www.calletres.cl" target='_blank'>Link</a>
              </div>
            </article>

            <article className="card3">
            <Image 
                  src={consorcio}
                  alt="Consorcio logo"
                  className="card3__image"  
                  width={120}
                  height={100}
              />
              <div className="card3__content | flow">
                <div className="card3__content--container | flow">
                  <h2 className="card3__title heading">Consorcio</h2>
                  <p className="card3__description description">
                    Análisis de Datos
                    <br />
                     Integración con Bloomberg para analizar datos de mercado y generar reportes a todas las principales acciones.
                  </p>
                </div>
                <a className="card3__button" href="https://consorcio.cl" target='_blank' >Link</a>
              </div>
            </article>


            

          </div>
        </div>
      </div>
    </section>
  );
}

Cases.propTypes = propTypes;
Cases.defaultProps = defaultProps;

export default Cases;
import React from "react";
// import sections
import Hero from "../components/sectionsHome/Hero";

import Testimonial from "../components/sectionsHome/Testimonial";

import FeaturesServices from "../components/sectionsHome/FeaturesServices";
import FeaturesWork from "../components/sectionsHome/FeaturesWork";
import WeAre from "components/sectionsHome/WeAre";
import Whatsapp from "components/whatsapp";
import { Helmet } from 'react-helmet-async';


const Home = () => {
  return (
    <>
    <Helmet >
      <title>Higher Bit Solutions - Transformando Negocios con Tecnología - Soluciones Innovadoras en TI para Empresas y Startups</title>
      <meta name="description" content="Descubre cómo Higher Bit Solutions impulsa la transformación digital con soluciones tecnológicas personalizadas.fusionamos la tecnología avanzada con la estrategia empresarial para potenciar PYMES y startups. Desde la automatización y el análisis de datos con ML/AI hasta el desarrollo de software a medida y asesoramiento estratégico, ofrecemos soluciones integrales para transformar y escalar tu negocio en la era digital" />
        <meta name="keywords" content="soluciones TI para empresas, automatización y análisis de datos, desarrollo de software a medida, asesoramiento para startups, ML y AI en negocios, servicios de TI para PYMES, innovación en tecnología empresarial, apoyo tecnológico para startups, transformación digital de empresas." />
        <link rel="canonical" href="https://www.hbsolutions.cl/" />
      <meta name="author" content="Higher Bit Solutions" />
      <meta name="robots" content="index, follow" />
    </Helmet>

      <Whatsapp />
      <Hero className='illustration-section-01' />
      <FeaturesServices hasBgColor invertColor />
      <FeaturesWork
        invertMobile
        imageFill
        className='illustration-section-02'
      />
      <WeAre hasBgColor invertColor />
      <Testimonial topDivider />
      
    </>
  );
};

export default Home;

import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const WhatsAppIcon = () => {
  const handleClick = () => {
    // Abre WhatsApp con el número y mensaje predefinido
    const message = encodeURIComponent(
      "Hola, te estoy contactando porque estoy interesado en desarrollar un proyecto de..."
    );
    window.open(`https://wa.me/message/3NMY4EBQKUA2G1`);
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "70px",
        right: "40px",
        backgroundColor: "#25D366",
        color: "white",
        padding: "10px",
        borderRadius: "50%",
        cursor: "pointer",
        zIndex: 9999,
      }}
      onClick={handleClick}
    >
      <FaWhatsapp style={{ fontSize: 40 }} />
    </div>
  );
};

export default WhatsAppIcon;

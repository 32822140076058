import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Input from './Input';
import Button from './Button';
import emailjs from 'emailjs-com';
import Select from './Select';

// Add your regex patterns
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  closeHidden: PropTypes.bool,
  name: PropTypes.string,
  email: PropTypes.string,
  number: PropTypes.string,
  msg: PropTypes.string,
};

const defaultProps = {
  children: null,
  show: false,
  closeHidden: false,
  name: '',
  email: '',
  number: '',
  msg: '',
};

const ContactModal = ({
  className,
  children,
  handleClose,
  show,
  closeHidden,
  ...props
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [msg, setMsg] = useState('');
  const [service, setService] = useState('');
  const [budget, setBudget] = useState('');
  const [loading, setLoading] = useState(false);

  const services = [["Higher Factory", 1], ["Bit Data", 2], ["Labs", 3]];

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate phone number, email, and amount
    const isEmailValid = emailRegex.test(email);

    if (name  && isEmailValid && service && budget && msg) {
      setLoading(true);

      const data = {
        name: name,
        number: number,
        message: msg,
        email: email,
        service: service,
        budget: budget,
      };

      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ADMIN,
          data,
          process.env.REACT_APP_USER_ID
        )
        .then(
          (result) => {
            console.log(result.text);
            alert('Tu mensaje ha sido enviado');
          },
          (error) => {
            console.log(error.text);
            alert('Hubo un error al enviar tu mensaje');
          }
        );

      setName('');
      setEmail('');
      setNumber('');
      setMsg('');
      setService('');
      setBudget('');
      setLoading(false);
    } else {
      alert('Por favor rellena todos los campos correctamente' );
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', stopProgagation);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', stopProgagation);
    };    
  });

  useEffect(() => {
    handleBodyClass();
  }, [props.show]); 
  
  const handleBodyClass = () => {
    if (document.querySelectorAll('.modal.is-active').length) {
      document.body.classList.add('modal-is-active');
    } else {
      document.body.classList.remove('modal-is-active');
    }
  }

  const keyPress = (e) => {
    e.keyCode === 27 && handleClose(e);
  }

  const stopProgagation = (e) => {
    e.stopPropagation();
  }

  const classes = classNames(
    'modal',
    show && 'is-active',
    className
  );

  return (
    <>
      {show &&
        <div
          {...props}
          className={classes}
          onClick={handleClose}
        >
          <div className='modal-inner' onClick={stopProgagation}>
            
              <>
                {!closeHidden &&
                  <button
                    className="modal-close"
                    aria-label="close"
                    onClick={handleClose}
                  ></button>
                }
                <div className="modal-content content-center">
                  {loading  ? 
                  <div class="loader">
                    <div class="bar" id="red"></div>
                    <div class="bar" id="mint"></div>
                    <div class="bar" id="grey"></div>
                    <div class="bar" id="purple"></div>
                  </div>
                  :
                  <div className="modal-content-inner">
                  <h1 className="mt-0 mb-16 ">
                    <span className="text-color-primary">Contacto</span> 
                  </h1>
                    <form>
                    
                      <div className="field ">
                       
                        <div className="control" style={
                          {marginBottom: '1rem'}
                        }>
                          <Input
                            className="form-input"
                            type="text"
                            status={name !== '' ? 'success' : ''}
                            placeholder="Juanito Perez"
                            label={'Nombre'}
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="field">
                      
                        <div className="control" style={
                          {marginBottom: '1rem'}
                        }>
                          <Input
                            className="form-input"
                            type="email"
                            label={'Email'}
                            placeholder="email@example.com"
                            value={email}
                            status={email!== "" ?  emailRegex.test(email) ? 'success' : 'error': ''}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="field">
                        
                        <div className="control" style={
                          {marginBottom: '1rem'}
                        }>
                          <Input
                            className="input"
                            type="text"
                            label={'Teléfono'}
                            placeholder="+56912345678"
                            value={number}
                            status={number !== "" ? 'success' : 'error'}
                            onChange={(e) => setNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <div className="control" style={
                          {marginBottom: '1rem'}
                        }>
                          <Select
                            value={service}
                            onChange={(e) => setService(e.target.value)}
                            className={"form-select"}
                            label={'Servicio'}
                            placeholder={"Servicio"}
                            children={services.map((item) => (
                              
                              <option key={item[1]} value={item[0]}>{item[0]}</option>
                            ))}
                          />
                        </div>
                      </div>
                      <div className="field">
                      
                        <div className="control" style={
                          {marginBottom: '1rem'}
                        }>
                          <Input
                            type="number"
                            label={'Presupuesto'}
                            placeholder="10000 USD"
                            value={budget}
                            status={budget !== "" ?  'success' : 'error'}
                            onChange={(e) => setBudget(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="field">
                      
                        <div className="control" style={
                          {marginBottom: '1rem'}
                        }>
                          <Input
                            type="textarea"
                            label={'Comentario'}
                            placeholder="Detalle del proyecto"
                            value={msg}
                            onChange={(e) => setMsg(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <div className="control">
                          <Button
                            color="primary"
                            onClick={handleSubmit}
                          >Enviar</Button>
                        </div>
                      </div>
                    </form>
                  </div>
                  }
                </div>
              </> 
          </div>
        </div>
      }
                 
    </>
  )
}

ContactModal.propTypes = propTypes;
ContactModal.defaultProps = defaultProps;

export default ContactModal;
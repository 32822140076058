import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const Benefits = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles  section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner mt-0 pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "¿Necesitas ",
    titleColor: "Ayuda TI?",
    paragraph:
      "Impulsamos tus ideas, aceleramos tu crecimiento y transformamos tu negocio",
  };

  return (
    <>
      <section {...props} className={outerClasses}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
          <path
            fill='#0A0D1D'
            fillOpacity='1'
            d='M0,32L720,96L1440,224L1440,0L720,0L0,0Z'
          ></path>
        </svg>

        <div className='container margin-neg '>
          <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>

              <div className='tiles-item reveal-from-bottom'   data-reveal-delay='400'>
                <div className='card'>
                    <h4 className=''> Impulsa tu Transformación Digital</h4>
                    
                    <p className='text-sm'>
                    Te ayudaremos a adoptar tecnologías innovadoras que optimicen tu forma de operar y permanecer competitivo digitalmente
                    </p>
                </div>
              </div>


              <div
                className='tiles-item reveal-from-bottom'
                data-reveal-delay='400'
              >
                <div className='card'>
                    <h4 className=''>Descubre más oportunidades</h4>
                  
                     
                    <p className='text-sm'>
                      Trabajaremos contigo para identificar las mejores oportunidades tecnologicas para tu negocio y expandir su horizonte de crecimiento
                    </p>
               </div>
              </div>




              <div
                className='tiles-item reveal-from-bottom'
                data-reveal-delay='400'
              >
                <div className='card'>
                    <h4 className=''> Innovación Impulsada por Tecnología</h4>
                     
                  <p className=' text-sm'>
                    Con nosotros, podrás adoptar las tecnologías mas innovadoras de la industria. Potencia las capacidades de tu startup con HBS
                    </p>
                
              </div>
            </div>
            </div>

          </div>
        </div>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
          <path
            fill='#0A0D1D'
            fillOpacity='1'
            d='M0,128L720,160L1440,64L1440,320L720,320L0,320Z'
          ></path>
        </svg>
      </section>
    </>
  );
};

Benefits.propTypes = propTypes;
Benefits.defaultProps = defaultProps;

export default Benefits;

import React from 'react';
import classNames from "classnames";
import { SectionProps } from "../utils/SectionProps";
import Image from 'components/elements/Image';
import notfound from '../assets/images/notfound.svg';



const propTypes = {
    ...SectionProps.types,
  };
  
  const defaultProps = {
    ...SectionProps.defaults,
  };
  
const NotFound = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    ...props
}) => {


  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    "bg-element",
    className
  );


  return (
    <section {...props} className={outerClasses}>
    <div
      className='container reveal-from-top'
      data-reveal-delay='800'
      style={{ height: "100vh" }}
    >
      <h1>404 Page Not Found</h1>
      <p>Oops! The page you are looking for does not exist.</p>
      
        <Image
            src={notfound}
            alt="404 Page Not Found"
            width={528}
            height={396}
            />
             
    </div>
  </section>
  );


};

NotFound.propTypes = propTypes;
NotFound.defaultProps = defaultProps;

export default NotFound;
import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import analysisAnimate from '../../assets/images/analysis-animate.svg';
import waterfallModelAnimate from '../../assets/images/waterfall-model-animate.svg';
import techCompanyAnimate from '../../assets/images/tech-company-animate.svg';
import dataExtractionAnimate from '../../assets/images/data-extraction-animate.svg';
import computerAnimate from '../../assets/images/computer-animate.svg';


const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesWork = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Know',
    titleColor:  'How?',
    paragraph: 'Creemos que la metodología Agile es la mejor para trabajar, por ello creamos un proceso de 5 etapas para lograr el éxito de tu proyecto.',
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container margin-neg">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Fase 1
                  </div>
                <h3 className="mt-0 mb-12">
                  Diagnóstico
                </h3>
                <p className="m-0">
                  En esta fase analizaremos el nivel de digitalización de tu negocio para generar un diagnóstico y así hacer fit con el servicio que necesitas.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
               <Image
                        src={analysisAnimate}
                        alt="Fase de analisis y diagnostico de tu nefocio, Discover, Analyze and Diagnose"
                        width={528}
                        height={396}
                      />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                 Fase 2
                  </div>
                <h3 className="mt-0 mb-12">
                Levantamiento de Requerimientos
                  </h3>
                <p className="m-0">
                  Ya con el diagnóstico podemos definir los requerimientos e hitos que compondrán el servicio que necesitas.

                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={waterfallModelAnimate}
                  alt="Fase de definicion de requerimientos y levantamiento de requerimientos, MVP, Waterfall Model, Agile Model"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Fase 3
                  </div>
                <h3 className="mt-0 mb-12">
                Transformación Digital
                  </h3>
                <p className="m-0">
                  En esta fase se integran las tecnologías y sistemas necesarias para lograr los hitos definidos en la fase anterior.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={techCompanyAnimate}
                  alt="Fase de transformacion digital, Data Transformation, Developing, Desarrollo de Software"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                 Fase 4
                  </div>
                <h3 className="mt-0 mb-12">
                Testing QA
                  </h3>
                <p className="m-0">
                  Una vez logrado un hito, realizaremos un control de calidad del servicio entregado para asegurar el cumplimiento de los requerimientos.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={dataExtractionAnimate}
                  alt="Fase de testing QA, Testing, Quality Assurance, QA"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Fase 5
                  </div>
                <h3 className="mt-0 mb-12">
                Soporte
                  </h3>
                <p className="m-0">
                Sabemos la responsabilidad que es tener un software, por lo mismo ofrecemos un soporte unico que se adapta a tus necesidades.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={computerAnimate}
                  alt="Fase de soporte, Support, Technical Support, Soporte tecnico"
                  width={528}
                  height={396} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesWork.propTypes = propTypes;
FeaturesWork.defaultProps = defaultProps;

export default FeaturesWork;
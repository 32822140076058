import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import strategies from '../../assets/images/strategies.svg';
import accompaniment from '../../assets/images/accompaniment.svg';
import opportunities from '../../assets/images/opportunities.svg';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const WorkTypes = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'cards',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Impulsando el ',
    titleColor:  'Éxito Tecnológico',
    paragraph: 'Te guiamos a través de procesos de cambio, asegurándonos de que tus decisiones tecnológicas estén alineadas con tus objetivos empresariales.',
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container margin-neg">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div className={splitClasses}>

            <div id="startup-services-1" className="card2-container">
              <div id="1"  className="card2">
              <div className="img-content">
              <div className='title' >
                Estrategia
                </div>

              <Image
                        src={strategies}
                        alt="Estrategia Tecnológica, desarrollo de soluciones de TI"
                        width={528}
                        height={396}
                        style={{
                          paddingLeft: "5px"
                        }}
                      />
              
              </div>
              <div className="content">
                <p className="heading">Estrategia Tecnológica</p>
                <p>
                Nuestro equipo de expertos en consultoría tecnológica trabaja mano a mano contigo para comprender tus objetivos y necesidades únicas. Transformamos conceptos complejos en soluciones prácticas y efectivas, guiándote en cada paso del camino
                </p>
              </div>
            </div>
            </div>

            <div id="startup-services-2" className="card2-container">
              <div className="card2">
              <div className="img-content">
              <div className='title' >
                Acompañamiento
                </div>
              <Image
                        src={accompaniment}
                        alt="Acompañamiento Digital, acompanamiento de TI"
                        width={528}
                        height={396}
                        style={{
                          paddingLeft: "5px"
                        }}
                      />
              
              </div>
              <div className="content">
                <p className="heading">Acompañamiento Digital</p>
                <p>
                Como partner tecnológico, te apoyamos en la definición y ejecución de estrategias que impulsen la transformación digital de tu empresa.
                </p>
              </div>
            </div>
            </div>

            <div id="3" className="card2-container">
              <div className="card2">
              <div className="img-content">
              <div className='title' >
                Oprtunidades
                </div>

              <Image
                        src={opportunities}
                        alt="Exploración de Oprtunidades, exploración de TI"
                        width={528}
                        height={396}
                        style={{
                          paddingLeft: "5px"
                        }}
                      />
              
              </div>
              <div className="content">
                <p className="heading">Exploración de Oprtunidades</p>
                <p>
                ¿Estás aprovechando al máximo las tecnologías disponibles?
              Evaluamos tu entorno tecnológico actual y futuras tendencias.
              Identificamos oportunidades para la adopción de soluciones innovadoras.
                </p>
              </div>
            </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

WorkTypes.propTypes = propTypes;
WorkTypes.defaultProps = defaultProps;

export default WorkTypes;
import React from "react";
// import sections
import Hero from "../components/sectionsStartUp/Hero";

import Cases from "../components/sectionsStartUp/Cases";
import Cta from "../components/sectionsStartUp/Cta";
import Benefits from "../components/sectionsStartUp/Benefits";
import WorkTypes from "../components/sectionsStartUp/WorkTypes";
import Process from "components/sectionsStartUp/Process";
import Whatsapp from "components/whatsapp";
import { Helmet } from "react-helmet-async";
const StartUp = () => {

  return (
    <>
    <Helmet>
      <title>Impulsando Startups: Asesoramiento y Recursos TI | Higher Bit Solutions</title>
      <meta name="description" content="Nuestro enfoque en startups se centra en guiar proyectos desde su idea inicial o etapa temprana, proporcionando recursos TI y asesoramiento estratégico. Ayudamos a las startups a evitar obstáculos comunes y a sentar las bases para un éxito sostenible en el mercado tecnológico." />
      <meta name="keywords" content="asesoramiento para startups, recursos TI para startups, desarrollo de software para startups, desarrollo de aplicaciones para startups, asesoramiento para startups" />
      <link rel="canonical" href="https://www.hbsolutions.cl/startup" />
      <meta name="author" content="Higher Bit Solutions" />
      <meta name="robots" content="index, follow" />
    </Helmet>
      <Whatsapp />  
      <Hero  />
      <Benefits hasBgColor invertColor />
      <WorkTypes
        invertMobile
        topDivider
        imageFill
      />
      <Process hasBgColor invertColor />
      <Cases topDivider />
      <Cta split />
    </>
  );
};

export default StartUp;

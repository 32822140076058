import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import mision from "../../assets/images/mision.svg";
import vision from "../../assets/images/vision.svg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const WeAre = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  imageFill,
  invertMobile,
  invertDesktop,
  alignTop,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: "Quienes ",
    titleColor: "Somos",
    paragraph:
      "Somos una empresa dedicada al servicio y soluciones tecnológicas, dirigida por Ingenieros de Software, interesados en proponer soluciones eficientes y de alta calidad.",
  };

  return (
    <section {...props} className={outerClasses}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 200'>
        <path
          fill='#0A0D1D'
          fillOpacity='1'
          d='M0,192L480,64L960,32L1440,128L1440,0L960,0L480,0L0,0Z'
        ></path>
      </svg>
      <div className='container margin-neg'>
        <div className={splitClasses}>
          <SectionHeader data={sectionHeader} className='center-content' />
          <div className={tilesClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                
                <h2 className="mt-0 mb-12">
                Misión
                  </h2>
                <p className="m-0">
                Nuestra misión es ser el socio tecnológico de confianza para las empresas, impulsando su rendimiento con soluciones de software personalizadas y análisis de datos precisos. Nos enfocamos en potenciar la toma de decisiones informadas, mejorando la eficiencia y rentabilidad de nuestros clientes, siempre con un enfoque en la integridad, la innovación y la excelencia en el servicio.

                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
               <Image
                        src={mision}
                        alt="Misión, socio tecnologico, confianza para las empresas, mejorando la eficiencia y rentabilidad de nuestros clientes"
                        width={528}
                        height={396}
                      />
              </div>
            </div>


            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
              
                <h2 className="mt-0 mb-12">
               Visión
                  </h2>
                <p className="m-0">
                Nuestra visión es ser el socio tecnológico que empodera a las empresas. Nos vemos fusionando soluciones de software personalizadas con análisis de datos avanzados para desbloquear su máximo potencial y  tomar de decisiones estratégicas basada en datos precisos y oportunos. Nos esforzamos por ser el catalizador que impulsa a las empresas hacia un futuro innovador y exitoso.

                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={vision}
                  alt="Visión, socio tecnologico que empodera a las empresas"
                  width={528}
                  height={396}/>
              </div>
            </div>

          </div>
        </div>
      </div>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 300'>
        <path
          fill='#0A0D1D'
          fillOpacity='1'
          d='M0,224L480,256L960,192L1440,0L1440,320L960,320L480,320L0,320Z'
        ></path>
      </svg>
    </section>
  );
};

WeAre.propTypes = propTypes;
WeAre.defaultProps = defaultProps;

export default WeAre;

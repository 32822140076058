import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import SoftwareFactory from './views/SoftwareFactory';
import NotFound from 'views/NotFound';
import DataAnalysis from 'views/DataAnalysis';
import StartUp from 'views/StartUp';


//const toTop
//when change route we want to scroll to top
 const toTop = () => {
  window.scrollTo(0, 0);

};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    document.body.classList.add('is-loaded')
    childRef.current.init();
    toTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    
      <ScrollReveal
        ref={childRef}
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/software" component={SoftwareFactory}  layout={LayoutDefault} />
            <AppRoute exact path="/data" component={DataAnalysis}  layout={LayoutDefault} />
            <AppRoute exact path="/startup" component={StartUp}  layout={LayoutDefault} />
            <AppRoute component={NotFound} layout={LayoutDefault} />
          
          </Switch>

        )} />
  );
}

export default App;
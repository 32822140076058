
import React, { useState, useEffect, useRef } from 'react';
import "./donut.css"
import {AiOutlineArrowRight, AiOutlineArrowLeft} from "react-icons/ai";
// Importa tu propio archivo CSS aquí

const DonutChart = () => {
  const chartRef = useRef(null);
  const [activeSection, setActiveSection] = useState(0);

  const sections = [
    {
      color: '#0A0D1D', 
      label: 'Exploración y Diagnóstico Tecnológico',
      description: [
        'Realizamos un análisis profundo de tus operaciones y tecnologías actuales.',
        'Identificamos áreas de mejora y oportunidades de optimización.',
        'Evaluamos la alineación de tus sistemas con tus objetivos empresariales.'
      ]
    },
    {
      color: '#9D1768', 
      label: 'Diseño de Estrategia Tecnológica',
      description: [
        'Desarrollamos una estrategia personalizada basada en tus necesidades.',
        'Proponemos soluciones tecnológicas y métodos para lograr tus metas.',
        'Definimos un enfoque claro y una hoja de ruta para la transformación digital.'
      ]
    },
    {
      color: '#DC1165', 
      label: 'Implementación y Acompañamiento',
      description: [
        'Ejecutamos la estrategia con eficiencia y en colaboración contigo.',
        'Gestionamos la implementación de soluciones y cambios tecnológicos.',
        'Proporcionamos asesoramiento y soporte durante todo el proceso.'
      ]
    },
    {
      color: '#EE7B57', 
      label: 'Evaluación y Mejora Continua',
      description: [
        'Medimos el impacto de las mejoras implementadas.',
        'Identificamos oportunidades adicionales para optimizar y evolucionar.',
        'Ajustamos la estrategia según los resultados y las necesidades cambiantes.'
      ]
    },
    {
      color: '#0A0D1D', 
      label: 'Logro de Resultados',
      description: [
        'Alcanzamos los objetivos establecidos en la estrategia.',
        'Impulsamos la eficiencia operativa y el crecimiento empresarial.',
        'Tu startup se beneficia de un enfoque tecnológico estratégico y efectivo.'
      ]
    },
];
const handleNextSection = () => {
  if(activeSection === sections.length - 1){
    setActiveSection(0);
    return;
  }
  setActiveSection((prevSection) => Math.min(prevSection + 1, sections.length - 1));
};

const handlePrevSection = () => {
  setActiveSection((prevSection) => Math.max(prevSection - 1, 0));
};

// Función para avanzar automáticamente cada 5 segundos
useEffect(() => {
  const intervalId = setInterval(() => {
    handleNextSection();
  }, 3000);

  return () => {
    clearInterval(intervalId);
  };
}, [activeSection]);

return (
  <div style={{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }} ref={chartRef}>
    <div className="slider-controls">
      <button onClick={handlePrevSection} disabled={activeSection === 0}
      className='transparent-button'
      >
      <AiOutlineArrowLeft size="50px"  />
      </button>
    </div>
    <div 
      className='container-donut'
    >
    <div  className="item">
      <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut">
        <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle>
        {sections.map((section, index) => (
            <circle
              key={index}
              cx="21"
              cy="21"
              r="15.91549430918954"
              fill="transparent"
              stroke={sections[activeSection].color}
              strokeWidth="3"
              strokeDasharray={`${(index + 1) * 20} ${100 - (index + 1) * 20}`}
              strokeDashoffset="25"
            ></circle>
        ))}
        <text x="21" y="21" alignmentBaseline="middle" textAnchor="middle" fill={sections[activeSection].color}>
          {activeSection + 1}
        </text>
      </svg>
    </div>
    <div className="item">
      <h2 style={{marginTop: 0}}>{sections[activeSection].label}</h2>
      <ul style={{padding:0}}>
        {sections[activeSection].description.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
    </div>
    </div>
    <div className="slider-controls">
      <button onClick={handleNextSection} disabled={activeSection === sections.length - 1}
        className='transparent-button'
      >
          <AiOutlineArrowRight size="50px" />
      </button>
    </div>
  </div>
);
};

export default DonutChart;
import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import mobile from '../../assets/images/smartphone.svg';
import web from '../../assets/images/websites.svg';
import integration from '../../assets/images/api.svg';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const WorkTypes = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'cards',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Tipos',
    titleColor:  'de Desarrollo',
    paragraph: 'Nos basamos en la metodología Agile para trabajar en equipo.',
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container margin-neg">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div className={splitClasses}>

            <div className="card2-container">

          
              <div className="card2">
              
              
             
              
              <div className="img-content">
              <div className='title' >
                Mobile
                </div>
              <Image
                        src={mobile}
                        alt="Aplicaciones moviles, desarrollo moviles, apps celular"
                        width={528}
                        height={396}
                        style={{
                          paddingLeft: "5px"
                        }}
                      />
            
              </div>

              <div className="content">
                <p className="heading">Mobile</p>
                <p>
                Creamos aplicaciones móviles a medida que brindan una experiencia excepcional a tus usuarios, aumentando la visibilidad de tu marca y mejorando la interacción con tus clientes
                </p>
              </div>
            </div>
            </div>

            <div className="card2-container">
              <div className="card2">
              <div className="img-content">
              <div className='title' >
                Desarrollo Web
                </div>
              <Image
                        src={web}
                        alt="Desarrollo Web, Paginas Web, Aplicaciones Web, Diseño Web"
                        width={528}
                        height={396}
                        style={{
                          paddingLeft: "5px"
                        }}
                      />
              
              </div>
              <div className="content">
                <p className="heading">Desarrollo Web</p>
                <p>
                Diseñamos y desarrollamos sitios web profesionales y personalizados que reflejan la identidad de tu empresa y ayudan a alcanzar tus objetivos comerciales
                </p>
              </div>
            </div>
            </div>

            <div className="card2-container">
              <div className="card2">
              <div className="img-content">
              <div className='title' >
                Integración
                </div>
              <Image
                        src={integration}
                        alt="Integración de sistemas, sistemas de información, ERP, CRM"
                        width={528}
                        height={396}
                        style={{
                          paddingLeft: "5px"
                        }}
                      />
              
              </div>
              <div className="content">
                <p className="heading">Integración de Sistemas</p>
                <p>
                Optimizamos tus procesos empresariales mediante la integración de sistemas, asegurando una comunicación fluida y eficiente entre tus aplicaciones y plataformas
                </p>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

WorkTypes.propTypes = propTypes;
WorkTypes.defaultProps = defaultProps;

export default WorkTypes;
import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import bgl from '../../assets/logos/bgl.png';
import swappi from '../../assets/logos/swappi.png';
import faro from '../../assets/logos/faro.webp';
import argus from '../../assets/logos/argus.png';
import consorcio from '../../assets/logos/consorcio.png';
import barrosleon from '../../assets/logos/barrosleon.png';




const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Transformando Negocios',
    titleColor: ' con Tecnología',
    paragraph: 'Clientes que han elevado su rendimiento gracias a nuestras soluciones.',
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-right" color='secondary'  data-reveal-delay="200">
          <a target="_blank" rel='noopener noreferrer' href="https://bgl.cl">
              <div className="tiles-item-inner center-content">
              <Image 
                  src={bgl}
                  alt="BGL logo"
                  width={120}
                  height={100} />
               
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Francisca</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a target="_blank" rel='noopener noreferrer' href="https://bgl.cl">BGL</a>
                  </span>
                </div>
              </div>
            </a>
            </div>
            <div className="tiles-item reveal-from-bottom">
            <a target="_blank" rel='noopener noreferrer' href="https://swappi.cl">
              <div className="tiles-item-inner center-content">
              <Image 
                  src={swappi}
                  alt="Swappi logo"
                  width={150}
                  height={100} />
                
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Ian</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a target="_blank" rel='noopener noreferrer' href="https://swappi.cl">Swappi</a>
                  </span>
                </div>
              </div>
            </a>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
            <a target="_blank" rel='noopener noreferrer' href="https://farocg.com"> 
              <div className="tiles-item-inner center-content">
              <Image 
                  src={faro}
                  alt="Faro logo"
                  width={150}
                  height={100} />
               
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Federico</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a target="_blank" rel='noopener noreferrer' href="https://farocg.com">Faro Consulting</a>
                  </span>
                </div>
              </div>
            </a>
            </div>
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
            <a  target="_blank" rel='noopener noreferrer' href="https://arguscapital.io">
              <div className="tiles-item-inner center-content">
              <Image 
                  src={argus}
                  alt="Argus logo"
                  width={150}
                  height={100}
                
                   />
                
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Vicente</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a  target="_blank" rel='noopener noreferrer' href="https://arguscapital.io">Argus Capital</a>
                  </span>
                </div>
              </div>
            </a>
            </div>
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
            <a target="_blank" rel='noopener noreferrer' href="https://consorcio.cl">
              <div className="tiles-item-inner center-content">
              <Image 
                  src={consorcio}
                  alt="Consorcio logo"
                  width={200}
                  height={160} />
                
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Francisco</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a target="_blank" rel='noopener noreferrer' href="https://consorcio.cl">Consorcio</a>
                  </span>
                </div>
              </div>
            </a>
            </div>
            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
            <a target="_blank" rel='noopener noreferrer' href="https://finzier.com">
              <div className="tiles-item-inner center-content">
              <Image 
                  src={barrosleon}
                  alt="Barros Leon logo"
                  width={160}
                  height={120} />
                
                <div className="testimonial-item-footer text-xs mt-0 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">Sofía</span>
                  <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a target="_blank" rel='noopener noreferrer' href="https://barrosleon.cl">Barros León</a>
                  </span>
                </div>
              </div>
            </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
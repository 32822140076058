import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import dataDriven from '../../assets/images/data-driven.svg';
import descriptivo from '../../assets/images/descriptivo.svg';
import diagnostico from '../../assets/images/diagnostico.svg';
import predictivo from '../../assets/images/predictivo.svg';
import prescriptivo from '../../assets/images/prescriptivo.svg';
import casual from '../../assets/images/casual.svg';


const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const WorkTypes = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'cards',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Potencia tu',
    titleColor:  'Análisis',
    paragraph: 'Descubre cómo nuestras técnicas de análisis pueden impulsar el crecimiento y éxito de tu empresa',
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container margin-neg">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div className={splitClasses}>

            <div className="card2-container">
              <div className="card2">
                <div className="img-content">
                  <div className='title' >
                    Exploratorio
                  </div>
              
                  <Image
                        src={dataDriven}
                        alt='Data-driven Process'
                        width={528}
                        height={396}
                        style={{
                          paddingLeft: "5px"
                        }}
                      />
              
                </div>
                <div className="content">
                  <p className="heading">Exploratorio</p>
                  <p>
                    Explora los datos para encontrar relaciones entre los mismos y formular distintas hipótesis a ser probadas
                  </p>
                </div>
              </div>
            </div>

            <div className="card2-container">
              <div className="card2">
                <div className="img-content">
                  <div className='title' >
                    Descriptivo
                  </div>
                  <Image
                        src={descriptivo}
                        alt='Descriptive Process'
                        width={528}
                        height={396}
                        style={{
                          paddingLeft: "5px"
                        }}
                      />
              
                </div>
                <div className="content">
                  <p className="heading">Descriptivo</p>
                  <p>
                  Proporciona una visión clara y concisa de los datos existentes, resumiendo patrones, tendencias y características importantes para comprender el estado actual del negocio.
                  </p>
                </div>
              </div>
            </div>

            <div className="card2-container">
              <div className="card2">
                <div className="img-content">
                  <div className='title' >
                    Diagnóstico
                  </div>
                  <Image
                        src={diagnostico}
                        alt='Diagnosis Process'
                        width={528}
                        height={396}
                        style={{
                          paddingLeft: "5px"
                        }}
                      />
              
                </div>
                <div className="content">
                  <p className="heading">Diagnóstico</p>
                  <p>
                  Identifica problemas y desafíos dentro del negocio, proporcionando una visión clara de las causas subyacentes y las posibles soluciones
                  </p>
                </div>
              </div>
            </div>

            <div className="card2-container">
              <div className="card2">
                <div className="img-content">
                  <div className='title' >
                    Predictivo
                  </div>
                  <Image
                        src={predictivo}
                        alt='Predictive Process'
                        width={528}
                        height={396}
                        style={{
                          paddingLeft: "5px"
                        }}
                      />
              
                </div>
                <div className="content">
                  <p className="heading">Predictivo</p>
                  <p>
                  Utiliza modelos estadísticos y algoritmos de machine learning para predecir eventos futuros y tendencias basadas en datos históricos.
                  </p>
                </div>
              </div>
            </div>

            <div className="card2-container">
              <div className="card2">
                <div className="img-content">
                  <div className='title' >
                    Prescriptivo
                  </div>

                <Image
                        src={prescriptivo}
                        alt='Prescriptive Process'
                        width={528}
                        height={396}
                        style={{
                          paddingLeft: "5px"
                        }}
                      />
              
                </div>
                <div className="content">
                  <p className="heading">Prescriptivo</p>
                  <p>
                  Va más allá del análisis predictivo y sugiere acciones específicas para lograr resultados deseados. Ofrece recomendaciones y escenarios para mejorar el rendimiento empresarial.
                  </p>
                </div>
              </div>
            </div>
            <div className="card2-container">
              <div className="card2">
                <div className="img-content">
                  <div className='title' >
                    Casual
                  </div>

                <Image
                        src={casual}
                        alt='Casual Process'
                        width={528}
                        height={396}
                        style={{
                          paddingLeft: "5px"
                        }}
                      />
              
                </div>
                <div className="content">
                  <p className="heading">Casual</p>
                  <p>
                    Estudio aleatorio centrado en la identificación de la casualidad entre variables relacionadas
                  </p>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </section>
  );
}

WorkTypes.propTypes = propTypes;
WorkTypes.defaultProps = defaultProps;

export default WorkTypes;
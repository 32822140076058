import React from "react";
// import sections
import Hero from "../components/sectionsSoftwareFactory/Hero";

import Cases from "../components/sectionsSoftwareFactory/Cases";
import Cta from "../components/sectionsSoftwareFactory/Cta";
import Benefits from "../components/sectionsSoftwareFactory/Benefits";
import WorkTypes from "../components/sectionsSoftwareFactory/WorkTypes";
import Tecnologies from "components/sectionsSoftwareFactory/Tecnologies";
import Whatsapp from "components/whatsapp";
import { Helmet } from "react-helmet-async";

const SoftwareFactory = () => {
  return (
    <>
    <Helmet >
      <title>Software Factory: Desarrollo a Medida para Impulsar tu Empresa a través de Innovación Tecnológica | Higher Bit Solutions</title>
      <meta name="description" content="Descubre cómo nuestra Software Factory crea soluciones de desarrollo de software personalizadas, impulsando la innovación tecnológica en tu empresa. Obtén aplicaciones a medida para alcanzar tus metas. Region Metropolitana, Chile, Latinoamérica" />
        <meta name="keywords" content="software factory, desarrollo de software, 
        desarrollo a medida, desarrollo de aplicaciones,
        desarrollo de aplicaciones móviles, desarrollo de aplicaciones web, 
        desarrollo de aplicaciones híbridas, desarrollo de aplicaciones nativas, 
        desarrollo de aplicaciones multiplataforma, desarrollo de aplicaciones para android, 
        desarrollo de aplicaciones para ios, desarrollo de aplicaciones para windows, 
        desarrollo de aplicaciones para mac, desarrollo de aplicaciones para linux, 
        desarrollo de aplicaciones para escritorio, desarrollo de aplicaciones para dispositivos móviles,
        Integración de sistemas, Integración ERP, Integración de CRM" />
        <link rel="canonical" href="https://www.hbsolutions.cl/software" />
      <meta name="author" content="Higher Bit Solutions" />
      <meta name="robots" content="index, follow" />
      </Helmet>
        


      <Whatsapp />  
      <Hero  />
      <Benefits hasBgColor invertColor />
      <WorkTypes
        invertMobile
        topDivider
        imageFill
      />
      <Tecnologies hasBgColor invertColor />
      <Cases topDivider />
      <Cta split />
    </>
  );
};

export default SoftwareFactory;

import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import python from "../../assets/images/python.svg";
import tableau from "../../assets/images/tableau.svg";
import redshift from "../../assets/images/Redshift.svg";
import hadoop from "../../assets/images/hadoop.svg";
import powerbi from "../../assets/images/powerbi.svg";
import quicksight from "../../assets/images/QuickSight.svg";
import postgres from "../../assets/images/postgres.svg";
import mongodb from "../../assets/images/mongodb.svg";
import s3 from "../../assets/images/s3.svg";
import lambda from "../../assets/images/Lambda.svg";
import glue from "../../assets/images/Glue.svg";


const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const Tecnologies = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
   
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "card-techs center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Tecnologías a la ",
    titleColor: "Vanguardia",
    paragraph:
      "Nuestro equipo de desarrollo está capacitado en una amplia gama de tecnologías y herramientas que nos permiten crear aplicaciones de calidad y a la medida de tus necesidades",
  };

  return (
    <section {...props} className={outerClasses}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 200'>
        <path
          fill='#0A0D1D'
          fillOpacity='1'
          d='M0,192L480,64L960,32L1440,128L1440,0L960,0L480,0L0,0Z'
        ></path>
      </svg>
      <div className='container margin-neg'>
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className='center-content' />
          <div className={tilesClasses}>
            <div className="cardtech   reveal-from-bottom">
            <Image
                      src={python}
                      alt='Python technology icon'
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">Python</p>
                  <span></span>
                  <p className="text price">Lenguaje</p>
                </div>
              </div>


            <div className="cardtech   reveal-from-bottom">
            <Image
                      src={redshift}
                      alt='Redshift technology icon'
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">AWS RedShift</p>
                  <span></span>
                  <p className="text price">Almacenamiento</p>
                </div>
              </div>


            <div className="cardtech   reveal-from-bottom">
            <Image
                      src={hadoop}
                      alt='Hadoop technology icon'
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">Hadoop</p>
                  <span></span>
                  <p className="text price">Almacenamiento</p>
                </div>
              </div>



           

            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={tableau}
                      alt='Tableau technology icon'
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">Tableau</p>
                  <span></span>
                  <p className="text price">Visualización</p>
                </div>
              </div>
            </div>

            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={powerbi}
                      alt='Power BI technology icon'
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">Power BI</p>
                  <span></span>
                  <p className="text price">Visualización</p>
                </div>
              </div>
            </div>
          
            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={quicksight}
                      alt='Quicksight technology icon'
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">AWS QuickSight</p>
                  <span></span>
                  <p className="text price">Visualización</p>
                </div>
              </div>
            </div>

            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={postgres}
                      alt='PostgreSQL technology icon'
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">PostgreSQL</p>
                  <span></span>
                  <p className="text price">Base de Datos</p>
                </div>
              </div>
            </div>



            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={mongodb}
                      alt='MongoDB technology icon'
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">MongoDB</p>
                  <span></span>
                  <p className="text price">Base de Datos</p>
                </div>
              </div>
            </div>

            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={s3}
                      alt='AWS S3 technology icon'
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">AWS S3</p>
                  <span></span>
                  <p className="text price">Almacenamiento</p>
                </div>
              </div>
            </div>

            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={lambda}
                      alt='AWS Lambda technology icon'
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">Lambda</p>
                  <span></span>
                  <p className="text price">Nube</p>
                </div>
              </div>
            </div>


            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={glue}
                      alt='AWS Glue technology icon'
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">Glue</p>
                  <span></span>
                  <p className="text price">ETL</p>
                </div>
              </div>
            </div>

            
            






           
          </div>
        </div>
      </div>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 300'>
        <path
          fill='#0A0D1D'
          fillOpacity='1'
          d='M0,224L480,256L960,192L1440,0L1440,320L960,320L480,320L0,320Z'
        ></path>
      </svg>
    </section>
  );
};

Tecnologies.propTypes = propTypes;
Tecnologies.defaultProps = defaultProps;

export default Tecnologies;

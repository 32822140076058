import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import DonutChart from "components/elements/DonutChart";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const Process = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Nuestro ",
    titleColor: "Proceso",
    paragraph:
      "Nuestro equipo de desarrollo está capacitado en una amplia gama de tecnologías y herramientas que nos permiten crear aplicaciones de calidad y a la medida de tus necesidades",
  };

  return (
    <section {...props} className={outerClasses}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 200'>
        <path
          fill='#0A0D1D'
          fillOpacity='1'
          d='M0,192L480,64L960,32L1440,128L1440,0L960,0L480,0L0,0Z'
        ></path>
      </svg>
      <div className='container parent-container-of-donut margin-neg'>
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className='center-content' />
        
            <div className='reveal-from-bottom'>
            
            <DonutChart />

           
          </div>
      
      </div>
    </div>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 300'>
        <path
          fill='#0A0D1D'
          fillOpacity='1'
          d='M0,224L480,256L960,192L1440,0L1440,320L960,320L480,320L0,320Z'
        ></path>
      </svg>
    </section>
  );
};

Process.propTypes = propTypes;
Process.defaultProps = defaultProps;

export default Process;

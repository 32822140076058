import React from "react";
// import sections
import Hero from "../components/sectionsData/Hero";

import Cases from "../components/sectionsData/Cases";
import Cta from "../components/sectionsData/Cta";
import Benefits from "../components/sectionsData/Benefits";
import WorkTypes from "../components/sectionsData/WorkTypes";
import Tecnologies from "components/sectionsData/Tecnologies";
import Whatsapp from "components/whatsapp";
import { Helmet } from "react-helmet-async";
const DataAnalysis = () => {

  return (
    <>
      <Helmet>
        <title>Análisis de Datos: Automatización y Análisis de Datos con ML/AI para PYMES, Empresas, Startups | Higher Bit Solutions</title>
        <meta name="description" content="Aprovecha el poder del Machine Learning y la Inteligencia Artificial para descubrir insights valiosos en tus datos. Nuestros servicios de análisis avanzados ayudan a las empresas a tomar decisiones informadas, prever tendencias y mejorar su estrategia comercial. Utilizamos tecnología avanzada para mejorar la eficiencia, reducir errores y aumentar la productividad, adaptándonos a las necesidades específicas de las pequeñas y medianas empresas. Obtén información valiosa para tomar decisiones estratégicas. Region Metropolitana, Chile, Latinoamérica" />
        <meta name="keywords" content="análisis de datos, transformación de datos, análisis de datos para negocios,  análisis de datos con AI, servicios de ML para empresas, inteligencia empresarial, predicción de tendencias con AI, optimización de datos con machine learning" />
        <link rel="canonical" href="https://www.hbsolutions.cl/data" />
        <meta name="author" content="Higher Bit Solutions" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Whatsapp />  
      <Hero  />
      <Benefits hasBgColor invertColor />
      <WorkTypes
        invertMobile
        topDivider
        imageFill
      />
      <Tecnologies hasBgColor invertColor />
      <Cases topDivider />
      <Cta split />
    </>
  );
};

export default DataAnalysis;

import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ContactModal from "components/elements/ContactModal";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [contactModalActive, setContactModalActive] = useState(false);

  const openContactModal = (e) => {
    e.preventDefault();
    setContactModalActive(true);
  };

  const closeContactModal = (e) => {
    e.preventDefault();
    setContactModalActive(false);
  };

  const outerClasses = classNames(
    " section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    "bg-element",
    className
  );



  return (
    <>

      <section {...props} className={outerClasses}>
        

           
            <div className='screen-30'>
           

              <h1 className='mb-16 reveal-from-top' data-reveal-delay='200'>
                 <span className='text-color-primary'>Higher</span> Factory
              </h1>
              <div className="containert"  data-reveal-delay='100'>
              <div className="content">
                <div className="content__containert">
                  <ul className="content__containert__list">
                    <li className="content__containert__list__item ">Web</li>
                    <li className="content__containert__list__item ">Mobile </li>
                    <li className="content__containert__list__item ">Automatización</li>
                    <li className="content__containert__list__item ">Integraciones</li>
                  </ul>
                </div>
              </div>
            </div>
              <div className='container-sm mt-32'>
              <a href="https://calendly.com/higher-bit-solutions/consultoria-hbs" target="_blank" rel="noopener noreferrer">
              <button data-text="Awesome"    className="button-a">
                  <span className="actual-text">&nbsp;Contáctanos&nbsp;</span>
                  <span className="hover-text" aria-hidden="true">&nbsp;Contáctanos&nbsp;</span>
              </button>
              </a>
              </div>
            </div>
            <ContactModal
            id='contact-modal'
            show={contactModalActive}
            handleClose={closeContactModal}
          />
      </section>
    </>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;

import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import swappi from '../../assets/logos/swappi.png';
import wandermap from '../../assets/logos/wandermap.png';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Cases = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'cards',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Casos de',
    titleColor: 'Éxito',
    paragraph: 'Clientes que han logrado una mejora de su negocio',
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>


            <article className="card3">
            <Image 
                  src={swappi}
                  alt="swappi, software de gestión operativa para administradores de comunidades"
                  className="card3__image"  
                  width={120}
                  height={100}
              />
              <div className="card3__content | flow">
                <div className="card3__content--container | flow">
                  <h2 className="card3__title heading">SWAPPI</h2>
                  <p className="card3__description description">
                    StartUp
                    <br />
                    Software de gestión operativa para administradores de comunidades
                  </p>
                </div>
                <a className="card3__button" href="https://www.swappi.cl" target='_blank' >Link</a>
              </div>
            </article>

            <article className="card3">
            <Image 
                  src={wandermap}
                  alt="wandermap, software que aprovecha la AI para hacer itinerarios de viajes personalizados"
                  className="card3__image"  
                  width={120}
                  height={100}
              />
              <div className="card3__content | flow">
                <div className="card3__content--container | flow">
                  <h2 className="card3__title heading">WanderMap</h2>
                  <p className="card3__description description">
                    StartUp
                    <br />
                    Software que aprovecha la AI para hacer itinerarios de viajes personalizados
                  </p>
                </div>
                <a className="card3__button" href="https://wandermap.io" target='_blank' >Link</a>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

Cases.propTypes = propTypes;
Cases.defaultProps = defaultProps;

export default Cases;
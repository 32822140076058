import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import python from "../../assets/images/python.svg";
import javascript from "../../assets/images/javascript.svg";
import typescript from "../../assets/images/typescript.svg";
import django from "../../assets/images/django.svg";
import react from "../../assets/images/react.svg";
import nodejs from "../../assets/images/node-js.svg";
import postgresql from "../../assets/images/postgres.svg";
import mongodb from "../../assets/images/mongodb.svg";
import aws from "../../assets/images/aws.svg";
import gcp from "../../assets/images/gcp.svg";
import redis from "../../assets/images/redis.svg";


const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const Tecnologies = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
   
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    " card-techs ",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Tecnologías a la ",
    titleColor: "Vanguardia",
    paragraph:
      "Nuestro equipo de desarrollo está capacitado en una amplia gama de tecnologías y herramientas que nos permiten crear aplicaciones de calidad y a la medida de tus necesidades",
  };

  return (
    <section {...props} className={outerClasses}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 200'>
        <path
          fill='#0A0D1D'
          fillOpacity='1'
          d='M0,192L480,64L960,32L1440,128L1440,0L960,0L480,0L0,0Z'
        ></path>
      </svg>
      <div className='container margin-neg'>
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className='center-content' />
          <div className={tilesClasses}>

          <div className='  reveal-from-bottom'>

            <div className="cardtech">
            <Image
                      src={python}
                      alt={"Python, lenguaje de programación"}
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">Python</p>
                  <span></span>
                  <p className="text price">Lenguaje</p>
                </div>
              </div>
            </div>

            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={javascript}
                      alt={"JavaScript, lenaguaje de programación"}
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">JavaScript</p>
                  <span></span>
                  <p className="text price">Lenguaje</p>
                </div>
              </div>
            </div>


            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={typescript}
                      alt={"TypeScript, lenguaje de programación"}
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">TypeScript</p>
                  <span></span>
                  <p className="text price">Lenguaje</p>
                </div>
              </div>
            </div>



           

            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={django}
                      alt={"Django, FrameWork"}
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">Django</p>
                  <span></span>
                  <p className="text price">FrameWork</p>
                </div>
              </div>
            </div>

            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={react}
                      alt={"React, FrameWork"}
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">React</p>
                  <span></span>
                  <p className="text price">FrameWork</p>
                </div>
              </div>
            </div>
          
            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={nodejs}
                      alt={"Node Js, FrameWork"}
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">Node Js</p>
                  <span></span>
                  <p className="text price">FrameWork</p>
                </div>
              </div>
            </div>

            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={postgresql}
                      alt={"PostgreSQL, Base de datos"}
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">PostgreSQL</p>
                  <span></span>
                  <p className="text price">Base de Datos</p>
                </div>
              </div>
            </div>



            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image  
                      src={mongodb}
                      alt={"MongoDB, Base de datos no relacional"}
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">MongoDB</p>
                  <span></span>
                  <p className="text price">Base de Datos</p>
                </div>
              </div>
            </div>

            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={redis}
                      alt={"Redis, Base de datos, Cache"}
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">Redis</p>
                  <span></span>
                  <p className="text price">Base de Datos</p>
                </div>
              </div>
            </div>

            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={aws}
                      alt={"AWS, Nube, Servidores"}
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">AWS</p>
                  <span></span>
                  <p className="text price">Nube</p>
                </div>
              </div>
            </div>


            <div className='  reveal-from-bottom'>
            <div className="cardtech">
            <Image
                      src={gcp}
                      alt={"GCP, Nube, Servidores"}
                      width={128}
                      height={128}
                      className={"imgtech"}
                    />
                <div className="textBox">
                  <p className="text head">GCP</p>
                  <span></span>
                  <p className="text price">Nube</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 300'>
        <path
          fill='#0A0D1D'
          fillOpacity='1'
          d='M0,224L480,256L960,192L1440,0L1440,320L960,320L480,320L0,320Z'
        ></path>
      </svg>
    </section>
  );
};

Tecnologies.propTypes = propTypes;
Tecnologies.defaultProps = defaultProps;

export default Tecnologies;
import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { Link } from "react-router-dom/cjs/react-router-dom";
import sfService from "./../../assets/images/sf-service.svg";
import dataService from "./../../assets/images/data-service.svg";
import startService from "./../../assets/images/start-service.svg";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesServices = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles mt-10 section",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );



  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Nuestros ",
    titleColor: "Servicios",
    paragraph:
      "En HBS nos enfocamos en brindar soluciones tecnológicas que se adapten a las necesidades de tu negocio. Para ello iniciamos con una consultoría de diagnóstico para ofrecerte el servicio que necesitas.",
  };
  return (
    <>
      <section {...props}  className={outerClasses}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'>
          <path
            fill='#0A0D1D'
            fillOpacity='1'
            d='M0,32L720,96L1440,224L1440,0L720,0L0,0Z'
          ></path>
      </svg>
        <div className='container margin-neg'>
          <div className={tilesClasses}>
            <SectionHeader data={sectionHeader} className='center-content' />
            <div className={tilesClasses}>
            <div className="card4 reveal-from-bottom">
            <div>
            <Image
                        src={sfService}
                        alt='Software Factory Services'
                        width={312}
                        height={312}
                      />
              <h3 className="">Higher Factory</h3>
              </div>

              <Link to="/software">
              <div className="card4__content  ">
              <p className="card4__title">Higher Factory</p>
                <p className="card4__description">
                  Desarrollamos aplicaciones 100% personalizadas enfocadas
                  en resolver tus necesidades y aumentar la productividad de
                  la organización.
                  </p>
              
                <button className="card4__btn">Ver más</button>
               
              </div>
              </Link>
            </div>

            <div className="card4 reveal-from-bottom">
            <div>
            <Image
                        src={startService}
                        alt='StartUp & Consulting'
                        width={312}
                        height={312}
                      />
                 <h3 className="">Higher Bit Labs </h3>
              </div>
               <Link to="/startup">
              <div className="card4__content" style={{
                backgroundColor: '#DC1165'
              }} >
              <p className="card4__title">StartUp & Consulting </p>
                <p className="card4__description">
               Tomamos tus ideas y las convertimos en realidad en estrecha colaboración con tu equipo,
              idetificando oportunidades de mejora a través de soluciones innovadoras generando valor e impacto en el negocio.
                  </p>
                <button className="card4__btn"
                style={{
                  color: '#DC1165'
                }}
                >Ver más</button>
              </div>
              </Link>
            </div>


            <div className="card4 reveal-from-bottom">
            <div>
            <Image
                        src={dataService}
                        alt='Big Data, Data Science & Analytics'
                        width={312}
                        height={312}
                      />
              
              <h3 className="">Bit Data</h3>
              </div>
              
              <Link to="/data">
              <div className="card4__content" style={{
                backgroundColor: '#9D1768'
              }} >
              <p className="card4__title">Bit Data</p>
                <p className="card4__description">
                Ofrecemos servicios de análisis de datos para ayudar a las
                      empresas a obtener información valiosa y tomar decisiones
                      informadas basadas en datos precisos y relevantes.
                  </p>
                <button className="card4__btn"
                style={{
                  color: '#9D1768'
                }}
                >Ver más</button>
              </div>
              </Link>
            </div>

              
            </div>
          </div>
        </div>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 300'>
        <path
          fill='#0A0D1D'
          fillOpacity='1'
          d='M0,224L480,256L960,192L1440,0L1440,320L960,320L480,320L0,320Z'
        ></path>
        </svg>
       
      </section>
     
    </>
  );
};

FeaturesServices.propTypes = propTypes;
FeaturesServices.defaultProps = defaultProps;

export default FeaturesServices;
